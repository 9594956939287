import classNames from 'classnames'

import useAppContext from 'app/store/useAppContext'
import { AffirmationForm } from 'features/affirmation/form/form'
import RegisterImg from 'shared/assets/images/contacts/RegisterImg.webp'
import { getCurrentDate } from 'shared/utils/functions'

import { contactsData } from '../../../model/contacts'

import cls from './Contacts.module.scss'

export const Contacts = () => {
  const {
    store: { isMobile, isMobileSmall, user },
    dispatch,
  } = useAppContext()

  const currentDate = getCurrentDate()

  const modalTitle = isMobile
    ? 'Оставьте свои контактные данные и начните зарабатывать пассивный доход'
    : 'Оставьте Ваши контакты, чтобы открыть инвест-счет'

  return (
    <section className={classNames('flex__row_center', cls.page)} id="contacts">
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          {isMobile && (
            <div className={cls.gift_sum}>
              <h4>
                Откройте инвест-счет
                <span>до {currentDate} и получите</span>
              </h4>
              <h3>{contactsData.investSum}</h3>
              <h5>К ВАШЕМУ ОСНОВНОМУ ПАКЕТУ</h5>
            </div>
          )}

          <img src={RegisterImg} alt="RegisterImg" />
        </div>

        <div className={cls.right_block}>
          <AffirmationForm user={user} dispatch={dispatch} title={modalTitle} />
        </div>

        <div className={cls.bg}></div>
      </div>
    </section>
  )
}

export const PHONE_MASK = "+7 (999) 999 99 99";
export const PAST_BUG_PHONE = "+7 (+7"
export const PAST_BUG_8_PHONE = '+7 (+8'

export const PHONE_TITLE = '+7'

export const PHONE_MATCHES_REG =
  /^\+7\s?\(?(7)(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-8])\)?\s?\d\d\d\s?\d\d\s?\d\d/
export const PHONE_MATCHES_ERR =
  "Проверьте код мобильного оператора!\n Код должен быть в диапазоне (700 - 778) \n Пример: +7 (7XX) XXX XX XX"
